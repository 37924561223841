import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "src/bundles/styles/Styles.css"
import jQuery from 'jquery';
import $ from 'jquery';
import classnames from 'classnames';
import { Collapse, Navbar, Nav, NavItem, Container } from "reactstrap"
import "./header.scss"

class Header extends React.Component {
  componentDidMount() {
    $(document).ready(function () {
      var mobileNav = "closed";

      $(".hamburger-wrapper").on("click", function () {
        var n = $("#mobile-nav-wrapper"), t = $(".hamburger")

        if (mobileNav === "closed") {
          $(n).animate({ right: "0" }, 300)
          mobileNav = "open"
          $(".hamburger").addClass("open")
        } else {
          $(n).animate({ right: "-999" }, 400)
          $("#mobile-nav-wrapper .sub-level-wrapper").animate({ right: "-999" }, 200)
          mobileNav = "closed"
          $(".hamburger").removeClass("open")
        }
      })
    })
  }
  render() {

    return (
      <header>
        <div className="center-wrapper">

          <span className="brand-logo">
            <a href="/">
              <img src={'/tysonscore3.azureedge.net/assets/media/wrightbrand/wright_brand_logo.png'} alt="Wright Brand" />
            </a>
          </span>
          <div id="nav-wrapper">
            <div id="nav-container">


              <div id="desktop-nav-wrapper">
                <ul className="nav navbar-nav ">
                  <NavItem className={`top-level`}>
                    <a href="/">Home</a>
                  </NavItem>
                  <NavItem className={`top-level`} >
                    <Link
                      to='/recipes/'
                      activeClassName="top-level active"
                    >
                      Recipes
                        </Link>
                  </NavItem>
                  <NavItem className={`top-level has-children`} >
                    <Link
                      to='/products/'
                      activeClassName="top-level active"
                    >
                      Products
                        </Link>
                    <div id="sub-nav-wrapper">
                      <ul>
                        <li>
                          <a href="/products/applewood-smoked/">Applewood Smoked Bacon<span className="nav-icon"></span></a>
                        </li>
                        <li>
                          <a href="/products/double-smoked-bacon/">Double Smoked Bacon<span className="nav-icon"></span></a>
                        </li>
                        <li>
                          <a href="/products/hickory-smoked/">Hickory Smoked Bacon<span className="nav-icon"></span></a>
                        </li>
                        <li>
                          <a href="/products/maple-flavored/">Maple Flavored Bacon<span className="nav-icon"></span></a>
                        </li>
                      </ul>
                    </div>
                  </NavItem>
                  <NavItem className={`top-level`} >
                    <Link
                      to='/how-to/how-to-oven-bake-bacon/'
                      activeClassName="top-level active"
                    >
                      How To
                        </Link>
                  </NavItem>
                  <NavItem className={`top-level`} >
                    <Link
                      to='/history/'
                      activeClassName="top-level active"
                    >
                      History
                        </Link>
                  </NavItem>
                  <NavItem className={`top-level`} >
                    <Link
                      to='/locator/'
                      activeClassName="top-level active"
                    >
                      Locator
                        </Link>
                  </NavItem>
                </ul>
              </div>

              <div className="hamburger-wrapper">
                <div className="hamburger">
                  <span className="first"></span>
                  <span className="second"></span>
                  <span className="third last"></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
