import React from "react"

let loadedScripts = []

export default class ScriptLoader extends React.Component {
  addScript = url => {
    if (loadedScripts.indexOf(url) === -1) {
      const script = document.createElement("script")
      script.src = url
      document.head.appendChild(script)
      loadedScripts.push(url)
    }
  }

  componentDidMount = () => {
    this.addScript(
      "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-562fba06c433a88e"
    )
    this.addScript(
      "https://ajax.googleapis.com/ajax/libs/jquery/1.11.2/jquery.min.js"
    )
    this.addScript("/Scripts.js")
  }

  render() {
    return null
  }
}
