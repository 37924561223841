import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import { RevealButton } from "@tyson-foods/react-component-library"
import Notification from "../Notification/Notification"
import { Button } from "reactstrap"
import "src/bundles/styles/Styles.css"
import "../../scss/footer.scss"

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer>
          <div class="container">
            <article>
              <div class="row">
                <div class="col-sm-12 col-xl-4 footer-description">
                  <section>
                    Copyright 2024 Tyson Foods. Trademarks and registered
                    trademarks are owned by Tyson Foods or its subsidiaries.
                    <div class=" link-list mt-2">
                      <ul>
                        <li>
                          <a href="/about-our-ads/" target="" title="">
                            <span class="general-link-container">
                              About our Ads
                            </span>
                            &nbsp;
                          </a>
                        </li>
                        <li>
                          <a href="/privacy-policy/" target="" title="">
                            <span class="general-link-container">
                               Privacy Policy
                            </span>
                            &nbsp;
                          </a>
                        </li>
                        <li>
                          <a href="/terms-of-use/" target="" title="">
                            <span className="general-link-container">
                               Terms of Use
                            </span>
                          </a>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          <a
                            href="/contact-us/"
                            title=""
                            href="https://app.keysurvey.com/f/1463959/4ffd/"
                            target="_blank"
                          >
                            <span className="general-link-container">
                              Do Not Sell My Information
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
                <div class="col-sm-12 col-xl-5 footer-links">
                  <section>
                    <div class=" link-list">
                      <ul>
                        <li>
                          <a href="/" target="" title="">
                            <span class="general-link-container">Home</span>
                          </a>
                        </li>
                        <li>
                          <a href="/recipes/" target="" title="">
                            <span class="general-link-container">Recipes</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="/how-to/how-to-oven-bake-bacon/"
                            target=""
                            title=""
                          >
                            <span class="general-link-container">How To</span>
                          </a>
                        </li>
                        <li>
                          <a href="/locator/" target="" title="">
                            <span class="general-link-container">Locator</span>
                          </a>
                        </li>
                        <li>
                          <a href="/contact-us/" target="" title="">
                            <span class="general-link-container">Contact</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
                <div class="col-sm-12 col-xl-3 footer-social d-xl-flex align-items-center mt-xl-2">
                  <section>
                    <div class=" link-list">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/wrightbrand"
                            target="_blank"
                            title=""
                            rel="noopener"
                          >
                            <img
                              src={
                                "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/fblogo.png"
                              }
                              alt="Like Us On Facebook"
                            />{" "}
                            <span class="general-link-container">Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.pinterest.com/wrightbacon/"
                            target="_blank"
                            title=""
                            rel="noopener"
                          >
                            <img
                              src={
                                "/tysonscore3.azureedge.net/assets/media/wrightbrand/images/icons/pinterest.png"
                              }
                              alt="Pinterest"
                            />{" "}
                            <span class="general-link-container">
                              Pinterest
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                </div>
              </div>
            </article>
          </div>
          <Notification
            className={"notification--gdpr"}
            text="Your privacy is important to us. We use cookies to ensure you get the best experience on this website.
                                For more information about how to control your cookies click learn more."
            buttonText={"Accept"}
            buttons={
              <Link to={"/privacy-policy/"}>
                <Button
                  color={"Primary"}
                  buttonColor={"Primary"}
                  type="button"
                  className={`notification__btn ml-3 position-relative btn`}
                >
                  Learn More
                </Button>
              </Link>
            }
          />
        </footer>
      </React.Fragment>
    )
  }
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Footer
