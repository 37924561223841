/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "src/bundles/styles/Styles.css"
import Header from "./header"
import Helmet from "react-helmet"
import { upperFirst } from "lodash"
import { BVLoader } from "@tyson-foods/react-component-library"
import ScriptLoader from "./ScriptLoader"
import Footer from "./Layout/Footer"

let bvLoaded = false
class Layout extends React.Component {
  render() {
    const {
      metaTitle,
      pageTitle,
      metaDescription,
      metaKeywords,
      children,
    } = this.props
    const title = metaTitle ? metaTitle : pageTitle ? upperFirst(pageTitle) : ""
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <Helmet
              htmlAttributes={{
                lang: "en",
              }}
              title={`${title ? title + " | " : ""}${
                data.site.siteMetadata.title
              }`}
              meta={[
                { name: "description", content: metaDescription },
                { name: "keywords", content: metaKeywords },
                { name: "theme-color", content: "#112E5E" },
                {
                  name: "google-site-verification",
                  content: "RHb6i3i4Fmew-dJ0UjQVqXSutrZKA7DfVpJAktUxK2M",
                },
              ]}
              link={[
                {
                  rel: "stylesheet",
                  type: "text/css",
                  href:
                    "https://fonts.googleapis.com/css?family=Sorts+Mill+Goudy|IM+Fell+Great+Primer",
                },
                {
                  rel: "stylesheet",
                  type: "text/css",
                  href:
                    "https://cdnjs.cloudflare.com/ajax/libs/yui/3.2.0/cssreset/reset-min.css",
                },
                {
                  rel: "stylesheet",
                  type: "text/css",
                  href:
                    "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap.min.css",
                },
                {
                  rel: "stylesheet",
                  type: "text/css",
                  href:
                    "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap-theme.min.css",
                },
              ]}
            ></Helmet>

            <ScriptLoader />

            <BVLoader
              clientName="wright-bacon"
              siteID="main_site"
              env={process.env.GATSBY_BAZAARVOICE_ENV}
            />

            <Header siteTitle={data.site.siteMetadata.title} />
            <div>
              <main>
                {children}

                <div id="mobile-nav-wrapper" class="border-box">
                  <ul class="mobile-nav">
                    <li>
                      <a href="/" class="top-level">
                        Home<span class="nav-icon"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/recipes/" class="top-level">
                        Recipes<span class="nav-icon"></span>
                      </a>
                    </li>
                    <li class="has-children">
                      <a href="/products/" class="children-link" data-level="0">
                        Products<span class="nav-icon"></span>
                      </a>

                      <span class="children-arrow"></span>

                      <div class="sub-level-wrapper level-0">
                        <ul class="mobile-nav">
                          <li class="back-header">
                            <span class="parent-link"></span>
                            <a
                              href="/products/"
                              class="list-header"
                              data-level="0"
                            >
                              Products<span class="nav-icon"></span>
                            </a>
                          </li>

                          <li>
                            <a href="/products/applewood-smoked/">
                              Applewood Smoked Bacon
                              <span class="nav-icon"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/products/brown-sugar-flavored/">
                              Brown Sugar Flavored Bacon
                              <span class="nav-icon"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/products/cowboy-rub-bacon/">
                              Cowboy Rub Bacon<span class="nav-icon"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/products/double-smoked-bacon/">
                              Double Smoked Bacon<span class="nav-icon"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/products/hickory-smoked/">
                              Hickory Smoked Bacon<span class="nav-icon"></span>
                            </a>
                          </li>
                          <li>
                            <a href="/products/maple-flavored/">
                              Maple Flavored Bacon<span class="nav-icon"></span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a
                        href="/how-to/how-to-oven-bake-bacon/"
                        class="top-level"
                      >
                        How To<span class="nav-icon"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/history/" class="top-level">
                        History<span class="nav-icon"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/locator/" class="top-level">
                        Locator<span class="nav-icon"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </main>
              <Footer></Footer>
            </div>
          </React.Fragment>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
